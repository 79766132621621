import React, { Fragment } from "react";

const About = () => {
  return (
    <Fragment>
      <div className="profile-image">
        <div className="profile-overlay" />
        <img
          src="/images/chiranjeev-thapliyal.jpg"
          alt="chiranjeev thapliyal"
        />
      </div>
      <p className="about-text">
        Hello stranger! 👋, my name is{" "}
        <span className="gradient-text">Chiranjeev Thapliyal</span> and I am a{" "}
        <span className="gradient-text">Full Stack Developer</span>, passionate
        about building digital products that improve everyday experience for
        people.
      </p>
      <p className="about-text">
        I love to work on exciting projects that test what I've learnt, whilst
        being exposed to the power and potential of the ever-evolving
        technologies around us. I'm always looking for better, and more
        importantly, ethical and accessible ways to solve the common problems we
        are faced with day-to-day.
      </p>
    </Fragment>
  );
};

export default About;
