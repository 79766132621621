import React from "react";

const Contact = () => {
  return (
    <section className="section-contact">
      <h1 className="heading-1">
        <span>Get in touch </span> <small>🤙</small>
      </h1>
      <h2 className="section-contact__h2">
        Thanks for stopping by, I’m currently looking to join a new team of
        creative designers and developers. If you think we might be a good fit
        for one another, give me a
        <a href="tel:+919643011147"> call 🇮🇳 &nbsp;</a>
        or send me an
        <a
          href="mailto:connect.chiranjeev@gmail.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          &nbsp; email 📧
        </a>
        .
      </h2>
    </section>
  );
};

export default Contact;
