import React from 'react';
import { FaGithub, FaLinkedin,  FaMedium } from 'react-icons/fa';

export const AboutContact = () => {
	return (
		<section className='section-about-contact'>
			<h1 className='heading-1'>
				<span>Let's connect: </span> <small />
			</h1>
			<div className='section-about-contact--links'>
				<a href='mailto:connect.chiranjeev@gmail.com' rel='noopener noreferrer' target='_blank'>
					<i className='fas fa-envelope' />
					<h4 className='heading-1'>connect.chiranjeev@gmail.com</h4>
				</a>
				<a href='https://github.com/chiranjeev-thapliyal' rel='noopener noreferrer' target='_blank'>
					<FaGithub />
					<h4 className='heading-1'>github.com/chiranjeev-thapliyal</h4>
				</a>
				<a href='https://linkedin.com/in/chiranjeev-thapliyal-605b561b8' rel='noopener noreferrer' target='_blank'>
					<FaLinkedin />
					<h4 className='heading-1'>linkedin.com/in/chiranjeev-thapliyal-605b561b8</h4>
				</a>
				<a href='https://medium.com/@chiranjeev-thapliyal' rel='noopener noreferrer' target='_blank'>
					<FaMedium />
					<h4 className='heading-1'>medium.com/@chiranjeev-thapliyal</h4>
				</a>
			</div>
		</section>
	);
};
