import { Helmet } from 'react-helmet';

const Meta = () => {
	return (
		<Helmet>
				<link type='stylesheet' href='https://use.fontawesome.com/releases/v5.15.4/css/all.css' />

				<meta name='theme-color' content='#10101A' />
				<meta name='apple-mobile-web-app-status-bar-style' content='#10101A' />
				<title>Chiranjeev Thapliyal &mdash; Full Stack Developer</title>
				<meta
					name='description'
					content='I&#39;m a self-taught Front End Developer and turning ideas into real life products is my calling.'
				/>
				<meta property='og:type' content='website' />
				<meta property='og:title' content='Chiranjeev Thapliyal &mdash; Full Stack Developer' />
				<meta property='og:url' content='https://chiranjeev-thapliyal.vercel.app/' />
				<meta property='og:image' content='webp/preview-image.png' />
				<meta
					property='og:description'
					content='I&#39;m a self-taught Front End Developer and turning ideas into real life products is my calling.'
				/>
				<meta name='twitter:title' content='Chiranjeev Thapliyal &mdash; Full Stack Developer' />
				<meta
					name='twitter:description'
					content='I&#39;m a self-taught Front End Developer and turning ideas into real life products is my calling.'
				/>
				<meta name='twitter:image' content='webp/preview-image.png' />
				<meta name='twitter:card' content='summary_large_image' />
				<meta name='twitter:url' content='https://chiranjeev-thapliyal.vercel.app/' />
			</Helmet>
	)
}

export default Meta;
