import "./styles/global.scss";

import Meta from "./components/Meta";
import Header from "./components/Header";
import About from "./components/Main/About";
import Projects from "./components/Main/Projects";
import Testimonials from "./components/Main/Testimonials";
import Contact from "./components/Main/Contact";
import Socials from "./components/Main/Socials";
import Footer from "./components/Footer";
import Skills from "./components/Main/Skills";
import { AboutContact } from "./components/Main/AboutContact";

function App() {
  return (
    <div id="menu-target">
      <Meta />
      <Header />
      <main className="container">
        <About />
        <AboutContact />
        <Projects />
        <Skills />
        <Testimonials />
        <Contact />
        <Socials />
      </main>
      <Footer />
    </div>
  );
}

export default App;
