const reviews = [
	{
		id: 0,
		name: 'Deevanshu Kumavat',
		userName: '@deevanshukumavat',
		reply: 'Amazing work with Faasos. 👏'
	},
	{
		id: 1,
		name: 'Saumya Rai ✨',
		userName: '@SaumyaRai29',
		reply: 'Amazing work sir! The backend was handled exceptionally!!😲👌👌'
	},
	{
		id: 2,
		name: 'Antony Chacko',
		userName: '@antonychacko',
		reply: 'As always, Awesome 😍'
	},
	{
		id: 3,
		name: 'Krishna Kesri',
		userName: '@krishnakesri',
		reply: 'Awesome work 👏👏'
	},
	{
		id: 4,
		name: 'Vandana Tiwari',
		userName: '@vandana',
		reply: 'Awesome👍'
	},
	{
		id: 5,
		name: 'Sushanth PS.',
		userName: '@SushanthPS',
		reply: 'Amazing work! 😀'
	},
	{
		id: 6,
		name: 'Sunny Dhanwani',
		userName: '@sunnydhanwani',
		reply: 'Great work, as always!'
	},
	{
		id: 7,
		name: 'Aditi Diksha',
		userName: '@aditidiksha',
		reply: 'Good work 👍'
	},
	{
		id: 8,
		name: 'MD Ali Shahanshah',
		userName: '@mdalishahshah',
		reply: 'Good job guys'
	},
	{
		id: 9,
		name: 'Saumya Rai',
		userName: '@SaumyaRai29',
		reply: 'Wonderful Sir!'
	},
	{
		id: 10,
		name: 'Krishna Kesri',
		userName: '@krishnakesri',
		reply: 'Awesome work 👏👏'
	},
	{
		id: 11,
		name: 'Antony Chacko',
		userName: '@antonychacko',
		reply: 'As always, Awesome 😍'
	},
	{
		id: 12,
		name: 'Vandana Tiwari',
		userName: '@vandana',
		reply: 'Awesome👍'
	}
];

export default reviews;
