import Faasos from "../assets/images/faasos.png";
import Practo from "../assets/videos/practo.mp4";
import WealthOS from "../assets/images/wealthOS-banner.png";

const projects = [
  {
    name: "wealthOS iOS App",
    info: [
      "Created backend (Vapor-based) RESTful APIs entirely in Swift to handle backend logic, ensuring robust data handling and server-side operations.",
      "Implemented user authentication using email with a verification link through Firebase, ensuring secure access.",
      "Integrated functionality to import contacts directly, facilitating quick setup for new transactions.",
    ],
    tech: [
      { title: "SwiftUI", svgName: "swiftui" },
      { title: "mongoDB", svgName: "mongodb" },
      { title: "Swift", svgName: "swift" },
    ],
    media: WealthOS,
    live: "https://apps.apple.com/in/app/wealthos/id6502291067",
    repo: "https://github.com/chiranjeev-thapliyal/wealthos",
    id: 1,
    isUnderMaintainance: false,
  },
  {
    name: "Linkedin Clone",
    info: [
      "Built the algorithm to generate connection recommendations.",
      "Created real-time messenger to chat with connections.",
      "Implemented user authentication.",
    ],
    tech: [
      { title: "mongoDB", svgName: "mongodb" },
      { title: "React", svgName: "reactjs" },
      { title: "NodeJS", svgName: "nodejs" },
    ],
    media: Practo,
    live: "https://linkedin-clone-masai.netlify.app/",
    repo: "https://github.com/chiranjeev-thapliyal/linkedin-client",
    id: 2,
    isUnderMaintainance: true,
  },
  {
    name: "Practo App Clone",
    info: [
      "Built with React using styled-components from scratch.",
      "Built desktop app and mobile app for the same using Progressive Web App (PWA).",
      "Filter doctors on the basis of gender, consultation fees, and other options.",
      "Going forward, I'm hoping to improve the API for booking.",
    ],
    tech: [
      { title: "HTML", svgName: "html-5" },
      { title: "CSS", svgName: "css3" },
      { title: "React", svgName: "reactjs" },
    ],
    media: Practo,
    live: "https://practo.vercel.app/",
    repo: "https://github.com/chiranjeev-thapliyal/practo",
    id: 3,
    isUnderMaintainance: true,
  },
  {
    name: "Faasos Replica",
    info: [
      "Dynamic location for food delivery using MapMyIndia API.",
      "Category-based food items with option to search with item name",
      "Chatbot to get recent orders/discount coupons, etc.",
    ],
    tech: [
      { title: "HTML", svgName: "html-5" },
      { title: "CSS", svgName: "css3" },
      { title: "JavaScript", svgName: "javascript" },
      { title: "mongoDB", svgName: "mongodb" },
    ],
    media: Faasos,
    live: "https://faasos-clone.herokuapp.com/",
    repo: "https://github.com/chiranjeev-thapliyal/faasos",
    id: 4,
    isUnderMaintainance: true,
  },
];

export default projects;
