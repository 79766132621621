import React from 'react'

const Socials = () => {
	return (
		<section className='section-socials'>
					<h1 className='heading-1'>
						<span>Dont be a stranger!</span> <small>👋</small>
					</h1>
					<p className='paragraph'>Connect with me online</p>
					<div className='section-socials--links'>
						<a href='https://github.com/chiranjeev-thapliyal' rel='noopener noreferrer' target='_blank'>
							<img src='/svg/github.svg' alt='chiranjeev-thapliyal-github' /> GitHub
						</a>
						<a
							href='https://www.linkedin.com/in/chiranjeev-thapliyal-605b561b8/'
							rel='noopener noreferrer'
							target='_blank'
						>
							<img src='/svg/linkedin.svg' alt='chiranjeev-thapliyal-linkedin' /> LinkedIn
						</a>
						<a href='https://medium.com/@chiranjeev-thapliyal' rel='noopener noreferrer' target='_blank'>
							<img src='/svg/medium.svg' alt="chiranjeev's medium" /> Medium
						</a>
					</div>
				</section>
	)
}

export default Socials
