const Skills = () => {
	return (
		<section className='section-skills'>
			<h1 className='heading-1'>
				<span>Tools I Use </span> <small></small>
			</h1>
			<div className='section-skills--links'>
				<a href='https://whatwg.org/' rel='noopener noreferrer' target='_blank'>
					<img src='/svg/html-5.svg' alt='html5 logo' />
					<h4 className="heading-1">HTML 5</h4>
				</a>
				<a href='https://www.w3.org/' rel='noopener noreferrer' target='_blank'>
					<img src='/svg/css3.svg' alt='css3 logo' />
					<h4 className="heading-1">CSS 3</h4>
				</a>
				<a href='https://www.javascript.com/' rel='noopener noreferrer' target='_blank'>
					<img src='/svg/javascript.svg' alt='javascript logo' />
					<h4 className="heading-1">Javascript</h4>
				</a>
				<a href='https://aws.amazon.com/' rel='noopener noreferrer' target='_blank'>
					<img src='/svg/amazon-web-services.svg' alt='amazon web services logo' />
					<h4 className="heading-1">AWS</h4>
				</a>
				<a href='https://reactjs.org/' rel='noopener noreferrer' target='_blank'>
					<img src='/svg/reactjs.svg' alt='reactjs logo' />
					<h4 className="heading-1">React </h4>
				</a>
				<a href='https://redux.js.org/' rel='noopener noreferrer' target='_blank'>
					<img src='/svg/redux.svg' alt='redux logo' />
					<h4 className="heading-1">Redux</h4>
				</a>
				<a href='https://nodejs.org/en/' rel='noopener noreferrer' target='_blank'>
					<img src='/svg/nodejs.svg' alt='nodejs logo' />
					<h4 className="heading-1">Node JS</h4>
				</a>
				<a href='https://www.mysql.com/' rel='noopener noreferrer' target='_blank'>
					<img src='/svg/mysql-logo.svg' alt='mysql logo' />
					<h4 className="heading-1">MySQL</h4>
				</a>
				<a href='https://www.mongodb.com/' rel='noopener noreferrer' target='_blank'>
					<img src='/svg/mongodb.svg' alt='mongodb logo' />
					<h4 className="heading-1">MongoDB</h4>
				</a>
				<a href='https://mui.com/' rel='noopener noreferrer' target='_blank'>
					<img src='/svg/material-ui.svg' alt='material ui logo' />
					<h4 className="heading-1">Material UI</h4>
				</a>
				<a href='https://git-scm.com/' rel='noopener noreferrer' target='_blank'>
					<img src='/svg/git.svg' alt='git logo' />
					<h4 className="heading-1">Git</h4>
				</a>

				<a href='https://www.cplusplus.com/doc/tutorial/' rel='noopener noreferrer' target='_blank'>
					<img src='/svg/c++.svg' alt='c++ logo' />
					<h4 className="heading-1">C++</h4>
				</a>
			</div>
		</section>
	);
};

export default Skills;
