import AllProjects from "../Projects";
import ProjectCard from "./ProjectCard";

const Projects = () => {
  return (
    <section id="sectionProjects" className="section-projects">
      <h1 className="heading-1">
        <span>Built By Me </span> <small>✌</small>
      </h1>
      <p className="paragraph">
        Each project is unique. Here are some of my works.
      </p>

      {AllProjects.map((item, index) => {
        return <ProjectCard key={index} {...item} />;
      })}
    </section>
  );
};

export default Projects;
