const Header = () => {
	return (
		<div className='header-wrapper'>
				<header className='header'>
					<div className='header__hero'>
						<div className='header__hero--heading'>
							<span>Turning ideas into </span> <br />
							<span>real life </span>
							<span className='header__hero--heading-gradient'>products </span>
							<br />
							<span>is my calling.</span>
						</div>
						<a
							data-scroll-to
							className='header__hero--cta'
							target='_blank'
							rel='noopener noreferrer'
							href='https://drive.google.com/file/d/1rDQPEfr8WNMyPBvHuKPHxxLI9U0AEH0U/view?usp=sharing'
						>
							VIEW RESUME
						</a>
					</div>
				</header>
				<div className='header__footer'>
					<div className='header__footer--left' />
					<div className='header__footer--right'>
						<a href='https://github.com/chiranjeev-thapliyal' rel='noopener noreferrer' target='_blank'>
							<img src="/svg/github.svg" alt="" />	GitHub
						</a>
						<a
							href='https://www.linkedin.com/in/chiranjeev-thapliyal-605b561b8/'
							rel='noopener noreferrer'
							target='_blank'
						>
						 <img src="/svg/linkedin.svg" alt="" />	LinkedIn
						</a>
						<a href='https://medium.com/@chiranjeev-thapliyal' rel='noopener noreferrer' target='_blank'>
						 <img src="/svg/medium.svg" alt="" />	Medium
						</a>
					</div>
				</div>
			</div>
	);
};

export default Header;
