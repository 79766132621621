import { FaAngleRight } from "react-icons/fa";

const ProjectCard = ({
  name,
  info,
  tech,
  media,
  live,
  repo,
  id,
  isUnderMaintainance,
}) => {
  console.log(name, info, tech, media, live, repo, id);
  return (
    <div className="project-card" key={id}>
      <div className="project-card--viewer">
        {name !== "Practo App Clone" && name !== "Linkedin Clone" && (
          <img src={media} alt={name} />
        )}
        {name === "Practo App Clone" && (
          <video src={"/videos/practo.mp4#t=6"} muted autoPlay loop />
        )}
        {name === "Linkedin Clone" && (
          <video src={"/videos/linkedin.mp4"} muted autoPlay loop />
        )}
        <div className="project-card--tech-stack">
          {tech.map((item, index) => (
            <div key={index}>
              <img src={`/svg/${item.svgName}.svg`} alt={item.title} />
              <h4>{item.title}</h4>
            </div>
          ))}
        </div>
        {isUnderMaintainance && (
          <div className="project-card--badge">Under Maintenance</div>
        )}
      </div>
      <div className="project-card-info">
        <h3 className="project-card--name">{name}</h3>
        <ul className="project-card--details">
          {info.map((text, index) => (
            <li key={index} className="project-card--point">
              <FaAngleRight />
              {text}
            </li>
          ))}
        </ul>
        <div className="project-card-buttons">
          {live && (
            <a target="_blank" rel="noopener noreferrer" href={live}>
              App
            </a>
          )}
          {repo && (
            <a target="_blank" rel="noopener noreferrer" href={repo}>
              Github
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
