import React from 'react'
import Reviews from '../Review';

const Testimonials = () => {
	return (
		<section
					data-scroll
					data-scroll-offset='35%'
					data-scroll-repeat={true}
					data-scroll-class='section-reviews__bg'
					className='section-reviews'
				>
					<div className='section-reviews__top'>
						<h1 className='heading-1'>
							<span>Mmmm, a little brag </span> <small> 😄 </small>
						</h1>
						<p className='paragraph paragraph__sub'>What people are saying about my recent projects</p>
					</div>
					<div className="section-reviews__bottom">
					<div className="section-reviews__bottom-wrapper review-card__anim1">
						{Reviews?.map((review) => (
							<div key={review.id} className="review-card">
								<div className="review-card__top">
									<div className="review-card__top--left">
										<p className="review-card__p">{review.name}</p>
										<h3 className="review-card__h3">{review.userName}</h3>
									</div>
									<div className="review-card__top--right">
										<img src="svg/linkedin-tags.svg" alt="linkedin icon" />
									</div>
								</div>
								<div className="review-card__bottom">
									<h2 className="review-card__h2">{review.reply}</h2>
								</div>
							</div>
						))}
					</div>
					<div className="section-reviews__bottom-wrapper review-card__anim2">
						{Reviews?.sort().map((review) => (
							<div key={review.id} className="review-card">
								<div className="review-card__top">
									<div className="review-card__top--left">
										<p className="review-card__p">{review.name}</p>
										<h3 className="review-card__h3">{review.userName}</h3>
									</div>
									<div className="review-card__top--right">
										<img src="svg/linkedin-tags.svg" alt="linkedin icon" />
									</div>
								</div>
								<div className="review-card__bottom">
									<h2 className="review-card__h2">{review.reply}</h2>
								</div>
							</div>
						))}
					</div>
				</div>
				</section>
	)
}

export default Testimonials
