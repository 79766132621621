import React from 'react'

const Footer = () => {
	return (
		<footer className="footer">
			<span className="gradient-text">Chiranjeev Thapliyal</span>
			<div className="footer__socials">
				<a
					href="https://chiranjeev-thapliyal.vercel.app/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src="svg/dribble.svg" alt="dribble logo" />
				</a>
				<a
					href="https://github.com/chiranjeev-thapliyal"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src="svg/github.svg" alt="github logo" />
				</a>
			</div>
		</footer> 
	)
}

export default Footer
